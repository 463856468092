import React, { useState } from 'react';
import './create-transaction-link.css';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import backArrow from '../../../Images/backIcon.svg';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import uuid from 'react-uuid';

const CreateTransactionLink = () => {

  // const [name, setName] = useState()
  const [amount, setAmount] = useState()
  // const [options, setOptions] = useState([])
  // const [mode, setMode] = useState('Custom')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const create_link = () => {

    if (!amount) {
      setLoading(false)
      return false
    }
    setLoading(true)
    let data = {
      name: "Float",
      amount,
      status: "success",
      type: "credit",
      tx_ref: uuid(),
      user_id: localStorage.getItem("id2"),
      payment_type: "bank_transfer"
    }

    axios.post('https://kudipal.herokuapp.com/payments/push-balance', data)
      .then(res => {
        setLoading(false)
        navigate('/dashboard/merchant-transactions')
      })
      .catch(err => {
        setLoading(false)
      })

  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container create-transaction-container">
      <DashboardNavbar />

      <header>
        <div className="back-btn" onClick={() => navigate("/dashboard/merchant-transactions")}>
          <img src={backArrow} alt="" />

          <p>Float</p>
        </div>

        <span className="create-transaction-cta">
          {/* <button className="save-draft-btn">Save As Draft</button> */}

          <button className="create-link-btn" onClick={() => create_link()}>Add</button>
        </span>
      </header>

      <main>
        <h4>Add Float</h4>

        <form action="">

          <label htmlFor="">Transaction Amount(NGN)</label>
          <input type="number" placeholder="Enter Transaction Amount" onChange={(ev) => setAmount(ev.target.value)} />

        </form>
      </main>
    </section>
  );
};

export default CreateTransactionLink;
