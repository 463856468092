import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import './dashboard-transaction.css';
import CsvDownloadButton from 'react-json-to-csv'
import { JsonToExcel } from "react-json-to-excel";

const TrasactionList = ({ transactions, transactions_show }) => {

  const [filterStatus, setFilterStatus] = useState('all');
  const [filterStatus2, setFilterStatus2] = useState('all');
  const [filter, setFilter] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [showFilter, setShowFilter] = useState(false);
  const [showFilter2, setShowFilter2] = useState(false);

  const filterOptions = [
    {
      filterType: 'All',
      availableApartment: transactions.length,
      filter: 'all'
    },
    {
      filterType: 'Credit',
      availableApartment: transactions.filter((h) => h.type === 'credit').length,
      filter: 'type'
    },
    {
      filterType: 'Debit',
      availableApartment: transactions.filter((h) => h.type === 'debit').length,
      filter: 'type'
    },
    {
      filterType: 'Card',
      availableApartment: transactions.filter((h) => h.meta.mode === 'card').length,
      filter: 'mode'
    },
    {
      filterType: 'USSD',
      availableApartment: transactions.filter((h) => h.meta.mode === 'ussd').length,
      filter: 'mode'
    },
    {
      filterType: 'Bank Transfer',
      availableApartment: transactions.filter((h) => h.meta.mode === 'bank transfer').length,
      filter: 'mode'
    },
    {
      filterType: 'Success',
      availableApartment: transactions.filter((h) => h.status === 'success').length,
      filter: 'status'
    },
    {
      filterType: 'Pending',
      availableApartment: transactions.filter((h) => h.status === 'pending').length,
      filter: 'status'
    },
    {
      filterType: 'Failed',
      availableApartment: transactions.filter((h) => h.status === 'failed').length,
      filter: 'status'
    },
  ];

  const filterOptions2 = [
    {
      filterType: 'All',
      availableApartment: transactions.length,
      filter: 'all'
    },
    {
      filterType: 'Flutterwave',
      availableApartment: transactions.filter((h) => h.meta.processor === 'flutterwave').length,
      filter: 'processor'
    },
    {
      filterType: 'Paystack',
      availableApartment: transactions.filter((h) => h.meta.processor === 'paystack').length,
      filter: 'processor'
    },
    {
      filterType: 'Opay',
      availableApartment: transactions.filter((h) => h.meta.processor === 'opay').length,
      filter: 'processor'
    },
    {
      filterType: 'Interswitch',
      availableApartment: transactions.filter((h) => h.meta.processor === 'interswitch').length,
      filter: 'processor'
    },
    {
      filterType: 'Global Accelerex',
      availableApartment: transactions.filter((h) => h.meta.processor === 'global accelerex').length,
      filter: 'processor'
    },

  ];

  return (
    <main>
      <div className="recent-transaction-table transaction-list-container">
        <header>
          <h4>All Transaction</h4>

          <div className='specials'>

            <span onClick={() => {
              setShowFilter2(!showFilter2)
              setShowFilter(false)
            }}>
              <Icon className="filter-icon" icon="mdi:filter-multiple-outline" />
              <p>Gateway</p>
            </span>

            <span onClick={() => {
              setShowFilter(!showFilter)
              setShowFilter2(false)
            }}>
              <Icon className="filter-icon" icon="mdi:filter-multiple-outline" />
              <p>Filter</p>
            </span>


            <CsvDownloadButton data={transactions.filter(tx => {
              if (filterStatus === 'all') return tx
              if (filter === 'type') return tx.type === filterStatus
              if (filter === 'mode') return tx.meta.mode === filterStatus
              if (filter === 'status') return tx.status === filterStatus
              return tx
            })} className='csv' filename="kudipal-transactions" >
              <span>
                <Icon className="filter-icon" icon="material-symbols:cloud-download-outline" />
                <p>Download CSV</p>
              </span>
            </CsvDownloadButton>

            {/* <span> */}
            <JsonToExcel
              title="Download Excel"
              data={transactions.filter(tx => {
                if (filterStatus === 'all') return tx
                if (filter === 'type') return tx.type === filterStatus
                if (filter === 'mode') return tx.meta.mode === filterStatus
                if (filter === 'status') return tx.status === filterStatus
                return tx
              })}
              fileName="kudipal-transactions"
              btnClassName="csv"
              btnColor="#060D1C"
            />

            {/* <Icon className="filter-icon" icon="material-symbols:cloud-download-outline" />
              <p>Download Excel</p> */}
            {/* </span> */}



          </div>

        </header>

        {showFilter && <div className="properties-page-filter-options">
          {filterOptions.map((filteroption, idx) => {
            return (
              <div
                className={
                  filterStatus === filteroption.filterType.toLowerCase()
                    ? 'filter-option active-option'
                    : 'filter-option'
                }
                onClick={() => {
                  setFilterStatus(filteroption.filterType.toLowerCase())
                  setFilter(filteroption.filter.toLowerCase())
                }
                }
              >
                <p>{filteroption.filterType}</p>
                <span>{filteroption.availableApartment}</span>
              </div>
            );
          })}
        </div>}


        {showFilter2 && <div className="properties-page-filter-options">
          {filterOptions2.map((filteroption, idx) => {
            return (
              <div
                className={
                  filterStatus2 === filteroption.filterType.toLowerCase()
                    ? 'filter-option active-option'
                    : 'filter-option'
                }
                onClick={() => {
                  setFilterStatus2(filteroption.filterType.toLowerCase())
                  setFilter2(filteroption.filter.toLowerCase())
                }
                }
              >
                <p>{filteroption.filterType}</p>
                <span>{filteroption.availableApartment}</span>
              </div>
            );
          })}
        </div>}

        <table className="recent-transactions">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Time</th>
              <th>Date</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>

          {!showFilter2 && <tbody>
            {/* todo: try to abstract this table into the dashboardrecenttxns*/}
            {transactions_show && transactions_show.filter(tx => {
              if (filterStatus === 'all') return tx
              if (filter === 'type') return tx.type === filterStatus
              if (filter === 'mode') return tx.meta.mode === filterStatus
              if (filter === 'status') return tx.status === filterStatus
              return tx
            }).map((trx) => {
              const created_date = new Date(trx.createdAt)
              let hours = created_date.getHours();
              let period = 'am'
              if (hours > 12) {
                hours = hours - 12;
                period = 'pm';
              }
              let minutes = created_date.getMinutes()

              return < tr >
                <td>{trx._id}</td>
                <td>{trx.name}</td>
                <td>&#8358;{trx.amount}</td>
                <td className="status">
                  <span>{trx.status}</span>
                </td>
                <td>{`${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`}</td>
                <td>{
                  `${created_date.getDate()}/${created_date.getMonth() + 1}/${created_date.getFullYear()}`
                }</td>
                {/* <td>1111</td> */}
              </tr>
            })}

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr>

            <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}

          </tbody>
          }

          {showFilter2 && <tbody>
            {/* todo: try to abstract this table into the dashboardrecenttxns*/}
            {transactions_show && transactions_show.filter(tx => {
              if (filter2 === 'all') return tx
              if (filter2 === 'type') return tx.type === filterStatus2
              if (filter2 === 'mode') return tx.meta.mode === filterStatus2
              if (filter2 === 'processor') return tx.meta.processor === filterStatus2
              if (filter2 === 'status') return tx.status === filterStatus2
              return tx
            }).map((trx) => < tr >
              <td>{trx._id}</td>
              <td>{trx.name}</td>
              <td>&#8358;{trx.amount}</td>
              <td className="status">
                <span>{trx.status}</span>
              </td>
              <td>{new Date(trx.createdAt).getTime()}</td>
              <td>{new Date(trx.createdAt).getDate()}</td>
              {/* <td>1111</td> */}
            </tr>)}

          </tbody>
          }
        </table>
      </div>
    </main>
  );
};

export default TrasactionList;
