import React, { useState } from 'react';
import './dashboard-transaction.css';
import FundModal from '../Modals/FundModal';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

const TransactionBalance = ({ balance, inflow, outflow, user, pending }) => {

  const [fund_modal, setFundModal] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {fund_modal ? (
        <FundModal setModal={setFundModal} bank={user.account_datails} />
      ) : (
        <>
          <div className="transaction-balance">
            <header className="">
              <h3>Transaction</h3>

              {/* <span className="transaction-cta">
                <button className="widthrawal-btn" onClick={() => navigate('/dashboard/withdrawal')}>Withdraw</button>
                <button className="fund-btn" onClick={() => setFundModal(true)}>Fund Wallet</button>
              </span> */}
            </header>

            <main>
              <div className="total-available-balance">
                <header>
                  <p>Current Balance</p>

                  <span onClick={() => {
                    navigate('/dashboard/add-float')
                  }}>
                    <Icon className='trend-up-icon' icon="icon-park-solid:add" />
                    Float
                  </span>
                </header>

                <h3>NGN{(Number(balance) - Number(pending)).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              <div className="total-available-balance">
                <header>
                  <p>Pending Balance</p>

                  {/* <span onClick={() => {
                    navigate('/dashboard/add-float')
                  }}>
                    <Icon className='trend-up-icon' icon="icon-park-solid:add" />
                    Float
                  </span> */}
                </header>

                <h3>NGN{Number(pending).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              <div className="total-available-balance">
                <header>
                  <p>Total Inflow</p>

                  {/* <span>
              <Icon className='trend-up-icon' icon="icon-park-solid:trend" />
              +20%
            </span> */}
                </header>

                <h3>NGN{Number(inflow).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              <div className="total-available-balance">
                <header>
                  <p>Total Outflow</p>

                  {/* <span>
              <Icon icon="icon-park-solid:trend" />
              +20%
            </span> */}
                </header>

                <h3>NGN{Number(outflow).toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</h3>
              </div>

              {/*   */}
            </main>
          </div>
        </>)}
    </>
  );
};

export default TransactionBalance;
