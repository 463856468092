import React, { useEffect, useState } from "react"
import "./dashboard-overview.css"
import DashboardNavbar from "../../../components/Dashboard Navbar/DashboardNavbar"
import OverviewBalance from "./OverviewBalance"
import DashboardRecentTransaction from "./DashboardRecentTransaction"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"

const DasbhoardContainer = () => {
  const [user, setUser] = useState({})
  // const [transactions, setTransaction] = useState([])
  const [home, setHome] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    let id = localStorage.getItem("id")

    axios
      .post("/get-user", {
        id: id,
      })
      .then((data) => {
        setUser(data.data.user)
      })
      .catch((err) => {
        console.log(err)
      })

    // axios
    //   .post("/transactions", {
    //     user_id: id,
    //   })
    //   .then((data) => {
    //     setTransaction(data.data.transactions)
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })

    axios
      .post("/home", {
        id,
      })
      .then((data) => {
        setLoading(false)
        setHome(data.data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
  }, [])

  if (loading) {
    return (
      <div>
        <div
          className="flex md:hidden"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "60%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        {/*Desktop */}
        <div
          className="hidden md:flex"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
        >
          <ClipLoader
            color={"#060D1C"}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    )
  }

  return (
    <section className="dashboard-overview-container">
      <DashboardNavbar name={user.first_name} />
      <OverviewBalance user={user} balances={home.balances} />
      <DashboardRecentTransaction transactions={home.transactions} />
    </section>
  )
}

export default DasbhoardContainer
