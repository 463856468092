import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../../../components/Dashboard Navbar/DashboardNavbar'
import TransactionBalance from './TransactionBalance'
import TrasactionList from './TrasactionList'
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import { DropDownFilter } from '../../../components/filter';

const timeInterval = ["Day", "Week", "Month", "Year"];
const DashboardUserTransactionContainer = () => {

  const [transactions, setTransaction] = useState([])
  const [transactions_show, setTransaction_show] = useState([])
  const [inflow, setInflow] = useState()
  const [outflow, setOutFlow] = useState()
  const [balance, setBalance] = useState()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [pending, setPending] = useState(0)
  const [interval, setInterval] = useState([new Date(new Date().setHours(0, 0, 0, 0)), new Date(new Date().setHours(24, 0, 0, 0))]);


  useEffect(() => {
    let id = localStorage.getItem("id")
    let id2 = localStorage.getItem("id2")
    setLoading(true)

    axios.post('/get-user', {
      id: id
    })
      .then(data => {
        setUser(data.data.user)
      })
      .catch(err => {
        console.log(err)
      })


    axios.post('/merchant-transactions', {
      user_id: id2,
      filter: interval
    })
      .then(data => {
        setTransaction(data.data.transactions)
        setTransaction_show(data.data.transactions_show)
        setInflow(data.data.inflow)
        setOutFlow(data.data.outflow)
        setBalance(data.data.balance)
        setPending(data.data.pending)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
      })

    // axios.post('/transactions', {
    //   user_id: id
    // })
    //   .then(data => {

    //     setInflow(data.data.inflow)
    //     setOutFlow(data.data.outflow)
    //     setLoading(false)
    //   })
    //   .catch(err => {
    //     setLoading(false)
    //     console.log(err)
    //   })

  }, [interval])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <section className='dashboard-overview-container'>

      <DashboardNavbar />
      <DropDownFilter timeInterval={timeInterval} interval={interval} setInterval={setInterval} />
      
      <TransactionBalance balance={balance} inflow={inflow} outflow={outflow} user={user} pending={pending} />

      <TrasactionList transactions={transactions} transactions_show={transactions_show} />
    </section>
  )
}

export default DashboardUserTransactionContainer
