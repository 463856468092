import React, { useEffect, useState } from 'react';
import './list.css';
import axios from '../../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom';


const List = () => {

  const [merchants, setMerchants] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    axios.post('/merchants', { id: localStorage.getItem('id') })
      .then(res => {
        setMerchants(res.data.merchants)
        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })

  }, [])

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className='list-container'>
      <header>
        <h3>Merchant List</h3>
        {/* <Link to="/dashboard/create-transaction-link">
          <button>Create New Link</button>
        </Link> */}
      </header>


      <table className="recent-transactions">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Email</th>
            <th>Balance</th>
            {/* <th>Status</th> */}

          </tr>
        </thead>

        <tbody>

          {merchants.map(li =>
            <tr onClick={() => {
              localStorage.setItem("id2", li._id)
              navigate('/dashboard/merchant-transactions')
            }}>
              <td>{li._id}</td>
              <td>{li.full_name}</td>
              <td>{li.email}</td>
              <td>{Number(li.balance).toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
            </tr>
          )}


          {/* <tr>
            <td>#11100</td>
            <td>Car Rental</td>
            <td>&#8358;120,000:00</td>
            <td className="status">
              <span>Pending</span>
            </td>
            <td>10:00am</td>
            <td>14th Jan, 2022</td>
            <td>1111</td>
          </tr>

          <tr>
            <td>#11100</td>
            <td>Car Rental</td>
            <td>&#8358;120,000:00</td>
            <td className="status">
              <span>Pending</span>
            </td>
            <td>10:00am</td>
            <td>14th Jan, 2022</td>
            <td>1111</td>
          </tr> */}

        </tbody>
      </table>
    </main>
  );
};

export default List;
