import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import BrandLogo from '../../../images/logo.svg';
import { useParams } from 'react-router-dom';

import axios from '../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const SetNewPassword = () => {
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [secured, setSecured] = useState("password")

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    axios
      .post(
        `/get-user`,
        {
          id: params.id,
        }
      )
      .then((res) => {
        setLoading(false);
        if (res.data.message === 'success') {
          // console.log(res.data)
        } else {
          alert(res.data.details);
        }
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  }, [params.id]);

  const reset_passsword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (password === '' || confirm_password === '') {
      setLoading(false);
      alert('Please fill all fields');
      return false;
    }
    if (password !== confirm_password) {
      setLoading(false);
      alert("Passwords don't match");
      return false;
    }
    let data = {
      password,
      user_id: params.id,
    };

    axios
      .post(
        `/reset-password`,
        data
      )
      .then((res) => {
        setLoading(false);
        if (res.data.message === 'success') {
          navigate('/sign-in');
        } else {
          alert(res.data.details);
        }
      })
      .catch((err) => {
        alert(err);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="sign-up-form-container">
      <div className="logo brand-logo">
        {/* <Link to="/" class="logo">
          <img src={BrandLogo} alt="" />
        </Link> */}
      </div>
      <header>
        <h2>Set New Password</h2>
        <p>
          Please choose your new password.
          <Link to="/sign-in-agent">Sign In</Link>
        </p>
      </header>

      <form action="">
        <span>
          <label htmlFor="">New Password</label>
          <input
            type={secured}
            placeholder="enter your password"
            id="password"
            name="password"
            onChange={(ev) => setPassword(ev.target.value)}
          />
        </span>

        <span>
          <label htmlFor="">Confirm Password</label>
          <input
            type={secured}
            placeholder="enter your password"
            id="password"
            name="password"
            onChange={(ev) => setConfirmPassword(ev.target.value)}
          />
        </span>


        <div style={{ display: "flex", alignItems: "center", }}>
          <input style={{ width: 30 }} name='check' type='checkbox' checked={secured !== "password" ? true : false} onChange={() => setSecured(secured === "password" ? "text" : "password")} />
          <label for="check" onClick={() => setSecured(secured === "password" ? "text" : "password")}>Show Password</label>
        </div>

        <div className="form-cta sign-in-form-cta">
          <button onClick={reset_passsword}>Set New Password</button>
        </div>
      </form>
    </main>
  );
};

export default SetNewPassword;
