import React, { useState } from "react"
import "./dashboard-settings.css"
import axios from "../../../components/axios"
import ClipLoader from "react-spinners/ClipLoader"
import "react-dropdown-now/style.css"
import { Icon } from "@iconify/react"
import "./dashboard-team.css"



export default function TeamRoles({ user, roles }) {
  const [loading, setLoading] = useState(false)

  // const [filterStatus, setFilterStatus] = useState("all")
  // const [filter, setFilter] = useState("all")
  // const [showFilter, setShowFilter] = useState(false)

  const [isPopUpShown, setPopUpShown] = useState(false);

  const [first_name, setFName] = useState("")
  const [last_name, setLName] = useState("")
  const [email, setMail] = useState("")
  const [role, setRole] = useState("")

  const add_team = () => {

    setLoading(true)

    let data = {
      first_name,
      last_name,
      email,
      role
    }

    axios.post('', data)
      .then(res => {

        setLoading(false)
      })
      .catch(err => {

      })

  }

  const AddTeamPopUp = () => {
    return (
      <div className="bg-[rgba(0,0,0,0.7)] flex flex-1 w-full h-full top-28 left-0 justify-center items-center absolute">
        <div className="bg-white w-[50%] h-[50%] mb-[10%] p-10 rounded-2xl">
          <Icon icon={'material-symbols:close'} className="w-6 h-6 absolute" onClick={() => { setPopUpShown(false) }} />
          <p
            className="text-primary text-2xl text-center mt-1"
            style={{
              fontFamily: "cabinet-grotesk-bold",
              fontStyle: "normal",
              fontWeight: 800,
            }}
          >
            Add Team Role
          </p>

          <div className="flex flex-row mt-7" >
            <span className="flex flex-col mt-7 mr-5">
              <label
                className="text-lg"
                style={{
                  fontFamily: "cabinet-grotesk-bold",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
                // htmlFor=""
              >
                First Name
              </label>
              <input
                className="border-primary h-12 rounded-md mt-2 p-4"
                style={{ borderWidth: 1 }}
                type="text"
                placeholder="Input First Name"
                value={first_name}
                onChange={(ev) => setFName(ev.target.value)}
              />
            </span>

            <span className="flex flex-col mt-7">
              <label
                className="text-lg"
                style={{
                  fontFamily: "cabinet-grotesk-bold",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
                
              >
                Last Name
              </label>
              <input
                className="border-primary h-12 rounded-md mt-2 p-4"
                style={{ borderWidth: 1 }}
                type="text"
                placeholder="Input Last Name"
                onChange={(ev) => setLName(ev.target.value)}
              />
            </span>

          </div>

          <span className="flex flex-col mt-7">
            <label
              className="text-lg"
              style={{
                fontFamily: "cabinet-grotesk-bold",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              
            >
              Email
            </label>
            <input
              className="border-primary h-12 rounded-md mt-2 p-4"
              style={{ borderWidth: 1 }}
              type="text"
              placeholder="Input email address"
              onChange={(ev) => setMail(ev.target.value)}
            />
          </span>

          <span className="flex flex-col mt-7">
            <label
              className="text-lg"
              style={{
                fontFamily: "cabinet-grotesk-bold",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              
            >
              Role
            </label>
            <input
              className="border-primary h-12 rounded-md mt-2 p-4"
              style={{ borderWidth: 1 }}
              type="text"
              placeholder="Officiate team role"
              onChange={(ev) => setRole(ev.target.value)}
            />

            <button
              className="bg-[#060D1C] transition duration-500 hover:bg-secondary hover:shadow-secondary text-white p-4 mt-14 rounded-xl"
              type="button"
              onClick={(ev) => add_team()}
            >
              Add Team Role
            </button>
          </span>
        </div>
      </div>
    )
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }
  return (
    <main>
      {
        isPopUpShown && (
          <AddTeamPopUp />
        )
      }

      <div className="recent-transaction-table transaction-list-container">
        <header>
          <h4>Teams</h4>

          <div className="">
            <button
              className="bg-[#060D1C] text-white p-4 rounded-full"
              type="button"
              onClick={() => { setPopUpShown(true) }}
            >
              Add Team Role
            </button>
          </div>
        </header>

        <table className="recent-transactions">
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Action</th>

              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {/* todo: try to abstract this table into the dashboardrecenttxns*/}
            {roles &&
              roles
                .filter((tx) => {
                  // if (filterStatus === "all") return tx
                  // if (filter === "type") return tx.type === filterStatus
                  // if (filter === "mode") return tx.meta.mode === filterStatus
                  // if (filter === "status") return tx.status === filterStatus
                  return tx
                })
                .map((trx) => (
                  <tr>
                    <td>{trx.email}</td>
                    <td>{trx.role}</td>
                    <td className="status">
                      <span>{trx.status}</span>
                    </td>
                    <td>{trx.action}</td>

                    {/* <td>1111</td> */}
                  </tr>
                ))}

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr>

            <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </main>
  )
}
