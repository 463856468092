import React, { useState } from 'react';
import './forgot-password.css';
import { Link } from 'react-router-dom';
// import BrandLogo from '../../../images/logo.svg';

import EmailSentModal from './EmailSentModal';
import axios from '../../components/axios';
import ClipLoader from "react-spinners/ClipLoader";

const ForgotPassword = () => {
  const [passwordResetModalOpen, setPasswordResetOpen] = useState(false);
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)

  const reset = (e) => {
    e.preventDefault();
    setLoading(true)

    if (email.trim() === "") {
      setLoading(false)
      alert("Please input email")
      return false
    }
    let data = {
      email: email.trim()
    }

    axios.post(`/forgot-password`, data)
      .then(res => {
        setLoading(false)
        if (res.data.message === "success") {
          setPasswordResetOpen(true);
        } else {
          alert(res.data.details)
        }
      })
      .catch(err => {
        alert(err)
        setLoading(false)
      })
  }

  if (loading) {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto"
      }} >
        <ClipLoader
          color={"#060D1C"}
          loading={loading}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  }

  return (
    <main className="sign-up-form-container">
      <div className="logo brand-logo">
        {/* <Link to="/">
          <img src={BrandLogo} alt="" />
        </Link> */}
      </div>
      <header>
        <h2>Forgot Password?</h2>
        <p>
          Let's help you reset it. We will send you instructions to help you
          rest it
        </p>
      </header>

      <form action="">
        <span>
          <label htmlFor="">Email</label>
          <input
            type="email"
            placeholder="enter your email"
            id="email"
            onChange={(ev) => setEmail(ev.target.value)}
          />

          <Link to="/sign-in">
            <p className="forgot-password">Rememered Password?</p>
          </Link>
        </span>

        <div className="form-cta sign-in-form-cta">
          <button onClick={reset}>Reset Password</button>
        </div>
      </form>

      {passwordResetModalOpen && (
        <EmailSentModal setPasswordResetOpen={setPasswordResetOpen} email={email} />
      )}
    </main>
  );
};

export default ForgotPassword;
