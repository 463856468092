import React from 'react';
import { useNavigate } from 'react-router-dom';

const txnHeaders = ["Id", "Narration", "Amount", "Status", "Time", "Date", "Type"]

const DashboardRecentTransaction = ({ transactions }) => {

  // const [trxs, setTrxs] = useState(transactions ? transactions : [])

  const navigate = useNavigate()

  return (
    <>
      <div className="recent-transaction-table">
        <header>
          <h4>Recent Transactions</h4>
          <span onClick={() => navigate('/dashboard/transactions')}>
            <p>View All</p>
          </span>
        </header>

        <main></main>
        <table className="recent-transactions overflow-x-scroll">
          <thead>
            <tr className='hidden lg:contents'>
              {
                txnHeaders.map((txnHeader, index) => <th key={index}>{txnHeader}</th>)
              }
              {/* <th>Id</th>
              <th>Narration</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Time</th>
              <th>Date</th>
              <th>Type</th> */}
              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {
              transactions && transactions.map(
                  (trx, index) => {
                    const created_date = new Date(trx.createdAt)
                    let hours = created_date.getHours();
                    let period = 'am'
                    if (hours > 12) {
                      hours = hours - 12;
                      period = 'pm';
                    }
                    let minutes = created_date.getMinutes()
                    return (
                        <tr key={index}>
                          <td>
                            <h5>{txnHeaders[0]}</h5>
                            {trx._id}</td>
                          <td>
                            <h5>{txnHeaders[1]}</h5>
                            {trx.name}</td>
                          <td>
                            <h5>{txnHeaders[2]}</h5>
                            &#8358;{trx.amount}</td>
                          <td className="status">
                            <h5>{txnHeaders[3]}</h5>
                            <span>{trx.status}</span>
                          </td>
                          <td>
                            <h5>{txnHeaders[4]}</h5>
                            {`${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${period}`}</td>
                          <td>
                            <h5>{txnHeaders[5]}</h5>
                            {
                            `${created_date.getDate()}/${created_date.getMonth() + 1}/${created_date.getFullYear()}`
                            }</td>
                          <td>
                            <h5>{txnHeaders[6]}</h5>
                            {trx.type}</td>
                          {/* <td>1111</td> */}
                        </tr>
                    )
                  }
                )
            }

            {/* <tr>
              <td>#11100</td>
              <td>Car Rental</td>
              <td>&#8358;120,000:00</td>
              <td className="status">
                <span>Declined</span>
              </td>
              <td>10:00am</td>
              <td>14th Jan, 2022</td>
              <td>1111</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DashboardRecentTransaction;
