import axios from 'axios';

const instance = axios.create({
  // baseURL: 'https://betatin-backend.herokuapp.com/'
  // baseURL: 'https://test-kudipal-backend.herokuapp.com/admin/'
  baseURL: "https://kudipal.herokuapp.com/admin/"
});

instance.interceptors.request.use(
  async (config) => {
    // if (!config.headers.Authorization) {
    //   const token = localStorage.getItem("token")
    //   // console.log("ttt: " + token)
    //   // console.log(config)
    //   if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //   }
    // }

    return config;
  },
  async(error) => {
    await Promise.reject(error)
  }
);

export default instance;